import React, { useEffect, useState } from "react";
import Header from "../../components/header";
import deliveryClasses from "./delivery.ke.ke.module.scss";
import DayTime from "../../components/day-time";
import PriceCard from "../../components/price-card";
import ContactUs from "../../components/contact-us";
import Footer from "../../components/footer";
import FaqQuestion from "../../components/faq-question";
import CompaniesForDeliver from "../../components/companies-for-deliver";
import { listPriceCards, TYPES_FORM } from "../../constants/homepage";
import DeliveryForm from "../../components/delivery-form";
import Carousel from "../../components/carousel";
import Page from "../../components/page";
import { isActivePickupDeliveryTime } from "src/helpers/additionalFuctions";
import AOS from "aos";
import "aos/dist/aos.css";
import { ANIMATION_TYPE } from "../../constants/animation";
import {
  KEY_ACCEPTED_COOKIES,
  QUESTIONS_KE_KE,
} from "../../constants/homepage";
import { getAcceptedCookie } from "../../services/acceptedCookie";
import CookieBanner from "../../components/cookie-banner/index";

function Delivery() {
  const [isAcceptedCookie, setIsAcceptedCookie] = useState(
    getAcceptedCookie(KEY_ACCEPTED_COOKIES)
  );
  useEffect(() => {
    AOS.init();
  }, []);
  return (
    <Page>
      <main>
        <section className={deliveryClasses.wrapper}>
          <Header showNav />
        </section>
        <section className={deliveryClasses.wrapper} data-aos={ANIMATION_TYPE}>
          <h1 className={deliveryClasses.title}>
            Doručenie v rámci Košíc v rovnaký deň
          </h1>

          <p className={deliveryClasses.titleText}>
            Prepravíme Vaše zásielky v rámci Košíc a okolia už v deň objednávky.
          </p>
        </section>
        <section className={deliveryClasses.sliderContainer}>
          <div
            className={deliveryClasses.deliveryForm}
            data-aos={ANIMATION_TYPE}
          >
            <DeliveryForm
              isHomePage={false}
              type={TYPES_FORM.KE_KE}
              aditionalClass
            />
          </div>
          <h2 data-aos={ANIMATION_TYPE} className={deliveryClasses.subtitle}>
            Na čo Doruč.to využijem v Košiciach?
          </h2>
          <div
            data-aos={ANIMATION_TYPE}
            className={deliveryClasses.wrapperCarousel}
          >
            <Carousel />
          </div>
        </section>
        <section className={deliveryClasses.wrapper} data-aos={ANIMATION_TYPE}>
          <div className={deliveryClasses.priceContainer}>
            <h2 className={deliveryClasses.subtitle}>
              Cenník doručenia v rámci Košíc
            </h2>
            <div className={deliveryClasses.price}>
              <PriceCard navigateTo="/ke-ke" {...listPriceCards[1]} />
            </div>
          </div>
        </section>
        <section className={deliveryClasses.wrapper} data-aos={ANIMATION_TYPE}>
          <FaqQuestion
            tabs={false}
            showMore={true}
            title="Časté otázky ohľadne doručenia v rámci Košíc"
            typeQuestions={QUESTIONS_KE_KE}
          />
        </section>
        <section data-aos={ANIMATION_TYPE}>
          <CompaniesForDeliver
            title="Doručujeme pre fyzické osoby aj pre firmy"
            className={deliveryClasses.border}
          />
        </section>
        <section data-aos={ANIMATION_TYPE}>
          <ContactUs />
        </section>
        <Footer />
        {isAcceptedCookie === null ? (
          <CookieBanner setIsAcceptedCookie={setIsAcceptedCookie} />
        ) : (
          ""
        )}
      </main>
    </Page>
  );
}

export default Delivery;
